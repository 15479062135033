<template>
  <b-card
    style="cursor: pointer"
    class="border-0"
    @click="emitClick"
    body-class="hp-knowledge-basic-card text-center"
  >
    <img
      width="160px"
      style="border-radius: 0.6rem"
      height="155px"
      v-if="img"
      :src="img"
      :alt="title"
    />

    <h4 class="mt-16">{{ title }}</h4>

    <p class="hp-p1-body hp-mb-0 mb-1">{{ desc }}</p>

    <p v-if="subtitle" class="hp-p1-body mb-1 mt-0">{{ subtitle }}</p>

    <p v-if="code" class="hp-p1-body hp-mb-0 mt-0">{{ code }}</p>

    <slot name="actions"></slot>
  </b-card>
</template>

<script>
export default {
  props: {
    title: { type: String },
    img: { required: false },
    desc: { type: String },
    subtitle: { default: null },
    code: { default: null },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
